import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/colebemis.com/colebemis.com/src/templates/note-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`By default, `}<a parentName="p" {...{
        "href": "https://cli.github.com/manual/gh_repo_clone"
      }}><inlineCode parentName="a">{`gh repo clone`}</inlineCode></a>{` uses HTTPS. To configure `}<inlineCode parentName="p">{`gh`}</inlineCode>{` to clone with SSH, run the following command:`}</p>
    <pre><code parentName="pre" {...{}}>{`gh config set git_protocol ssh
`}</code></pre>
    <p>{`Thanks for the tip, `}<a parentName="p" {...{
        "href": "https://twitter.com/ampinsk"
      }}>{`@ampinsk`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      